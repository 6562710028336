import React from "react";
import "./work.css";
import Image from "../components/image";
class Work extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photos: [],
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });

        // call api to list new photos loaded for the backend
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/photos`
        );
        const photoJson = await response.json();
        this.setState({
            photos: photoJson,
        });
        this.setState({ loading: false });
    }
    render() {
        let { photos, loading } = this.state;

        return (
            <div className="workImages">
                {loading ? (
                    <div className="loadingContainer">
                        <img
                            className="loadingContainer__loadingAnimation"
                            src={process.env.PUBLIC_URL + "/loading.png"}
                        ></img>
                    </div>
                ) : (
                    <ul className="imagesContainer">
                        {photos
                            ? photos.map(function (photo) {
                                  return (
                                      <li className="imagesContainer__card">
                                          <Image
                                              className="imagesContainer__image"
                                              src={
                                                  process.env
                                                      .REACT_APP_API_BASE_URL +
                                                  "/photos/" +
                                                  photo
                                              }
                                          />
                                      </li>
                                  );
                              })
                            : ""}
                    </ul>
                )}
            </div>
        );
    }
}

export default Work;
