import "./Header.css";
import { Link } from "react-router-dom";
import { ReactComponent as MenuIcon } from "./assets/new_menu_icon.svg";

function SiteMap() {
    return (
        <header className="siteMap">
            <div className="siteMap__container">
                <div className="dropdown">
                    <button className="dropdown__button">
                        <MenuIcon className="button__icon" />
                    </button>
                    <div className="dropdown__content">
                        <a>
                            <Link className="dropdown__link" to="/" id="about">
                                About
                            </Link>
                        </a>
                        <a>
                            <Link
                                className="dropdown__link"
                                to="/work"
                                id="work"
                            >
                                Work
                            </Link>
                        </a>
                        <a>
                            <Link
                                className="dropdown__link"
                                to="/services"
                                id="services"
                            >
                                Services
                            </Link>
                        </a>
                        <a>
                            <Link
                                className="dropdown__link"
                                to="/contact"
                                id="contact"
                            >
                                Contact
                            </Link>
                        </a>
                    </div>
                </div>
                <div className="siteMap__name">Britt Paige Hair</div>
            </div>
        </header>
    );
}

export default SiteMap;
