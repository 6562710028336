import React, { Component } from "react";
import ModalImage from "react-modal-image";

const _loaded = {};
class Image extends Component {
    static defaultProps = {
        className: "",
        loadingClassName: "loading",
        loadedClassName: "loaded",
    };

    constructor(props, context) {
        super(props, context);
        this.state = { loaded: _loaded[props.src] };
    }

    onLoad = () => {
        _loaded[this.props.src] = true;
        this.setState(() => ({ loaded: true }));
    };

    render() {
        let { className, loadedClassName, loadingClassName, ...props } =
            this.props;
        className = `${className} ${
            this.state.loaded ? loadedClassName : loadingClassName
        }`;
        let position = this.props.src.lastIndexOf("/") + 1;
        let smallSrc = [
            this.props.src.slice(0, position),
            "small-",
            this.props.src.slice(position),
        ].join("");

        return (
            <div
                onLoad={this.onLoad}
                style={this.state.loaded ? {} : { display: "none" }}
            >
                <ModalImage
                    className={className}
                    hideDownload={true}
                    hideZoom={true}
                    small={smallSrc}
                    large={this.props.src}
                />
            </div>
        );
    }
}

export default Image;
