import React from "react";
import ReactMarkdown from "react-markdown";
import "./aboutMe.css";

class AboutMe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markdownText: "",
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/about_me.md`)
            .then((response) => response.text())
            .then((text) => {
                this.setState({ markdownText: text });
            });
    }

    render() {
        return (
            <div className="infoContainer__background__aboutMe">
                <div className="infoContainer__aboutMe">
                    <link rel="manifest" href="manifest.webmanifest"></link>
                    <div className="photoContainer">
                        <img
                            className="photoContainer__photo"
                            src={`${process.env.PUBLIC_URL}` + "/about_me.jpg"}
                        />
                    </div>
                    <div className="infoContainer__textContainer">
                        <ReactMarkdown className="textContainer">
                            {this.state.markdownText}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutMe;
