import React from "react";
import "./contact.css";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markdownText: "",
            emailName: "",
            emailBody: "",
            phoneNumber: "",
        };
    }

    checkMailPopulated = () => {
        const empty = (e) => e === "";
        return ![
            this.state.emailBody,
            this.state.emailName,
            this.state.phoneNumber,
        ].some(empty);
    };

    sendMail = () => {
        if (this.checkMailPopulated()) {
            window.location.href =
                "mailto:brittanie@brittpaige.hair?subject=Website Hair Styling Inquiry - " +
                this.state.emailName +
                " (" +
                this.state.phoneNumber +
                ")" +
                "&body=" +
                this.state.emailBody;
        } else {
            console.error(
                "Your name was not provided or the email body was not filled out"
            );
        }
    };

    setFormValue = (formPart, e) => {
        switch (formPart) {
            case "name":
                this.setState({
                    emailName: e.target.value,
                });
                break;
            case "phone":
                this.setState({
                    phoneNumber: e.target.value,
                });
                break;
            case "body":
                this.setState({
                    emailBody: e.target.value,
                });
                break;
        }
    };

    render() {
        let setFormValue = this.setFormValue.bind(this);
        let sendMail = this.sendMail.bind(this);

        return (
            <div className="infoContainer__background__contact">
                <div className="infoContainer__contact">
                    <link rel="manifest" href="manifest.webmanifest"></link>
                    <form className="emailForm">
                        <label>*Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.emailName}
                            placeholder="..."
                            onChange={(e) => setFormValue("name", e)}
                            required
                        />
                        <label>*Phone Number</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.phoneNumber}
                            placeholder="..."
                            onChange={(e) => setFormValue("phone", e)}
                            required
                        />
                        <label>*Message</label>
                        <textarea
                            className="emailBody"
                            rows="5"
                            value={this.state.emailBody}
                            placeholder="Hi my name is so and so and I'd like to schedule a such and such and a time and date..."
                            onChange={(e) => setFormValue("body", e)}
                        />
                    </form>
                    <button
                        type="submit"
                        className="submitEmailButton"
                        onClick={() => sendMail()}
                    >
                        Send
                    </button>
                </div>
            </div>
        );
    }
}

export default Contact;
