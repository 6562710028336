import React from "react";
import "./services.css";
import serviceContainer from "../components/serviceContainer";
import { Link } from "react-router-dom";

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesMap: [
                {
                    serviceHeader: "Cuts",
                    services: [
                        "Bangs - $10",
                        "Kids cut - $20",
                        "Kids cut with style - $30",
                        "Clipper cuts - $30",
                        "Haircuts - $50",
                    ],
                },
                {
                    serviceHeader: "Color",
                    services: [
                        "Toner/Gloss - $35+",
                        "Root touch up - $65+",
                        "Single all over color - $85+",
                        "Highlights - $100+",
                        "Partial highlights - $75+",
                        "Balayage - $150+",
                        "Double process - $200+",
                        "Vivid - $200+",
                    ],
                },
                {
                    serviceHeader: "Treatments",
                    services: [
                        "Deep conditioning treatments - $20-30",
                        "Brazilian blowout - $200",
                        "Express Brazilian blowout - $99",
                    ],
                },
                {
                    serviceHeader: "Styling",
                    services: [
                        "Blow out - $40",
                        "Speciality Blow out - $60",
                        "Up do - $40+",
                    ],
                },
                {
                    serviceHeader: "Waxing",
                    services: [
                        "Eyebrows - $12",
                        "Lip - $12",
                        "Chin - $20",
                        "Full face - $30",
                    ],
                },
                {
                    serviceHeader: "Extensions - Consultation Only",
                    services: ["Tape in extensions", "K tip extensions"],
                },
            ],
        };
    }

    render() {
        return (
            <div className="infoContainer">
                <link rel="manifest" href="manifest.webmanifest"></link>
                <button className="contactButton">
                    <Link className="contactButton__link" to="/contact">
                        Contact About a Visit
                    </Link>
                </button>
                <ul className="infoContainer__servicesContainer">
                    {this.state.servicesMap.map(function (service) {
                        return (
                            <li className="infoContainer__serviceEntry">
                                {serviceContainer(
                                    service.serviceHeader,
                                    service.services
                                )}
                            </li>
                        );
                    })}
                </ul>
                <div className="servicesInfoContainer__text">
                    <p>
                        *All services are set at a base price, which can
                        increase based on hair type, texture, density, product
                        usage, and time required to complete your desired look.
                    </p>
                </div>
            </div>
        );
    }
}

export default Services;
