import "./serviceContainer.css";
import Collapsible from "react-collapsible";

const ServiceContainer = (serviceHeader, servicesArray) => {
    return (
        <Collapsible trigger={serviceHeader} transitionTime={100}>
            <ul className="serviceContainer__services">
                {servicesArray
                    ? servicesArray.map(function (service) {
                          return (
                              <li className="serviceContainer__service">
                                  {service}
                              </li>
                          );
                      })
                    : null}
            </ul>
        </Collapsible>
    );
};

export default ServiceContainer;
