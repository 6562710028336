import "./App.css";
import SiteMap from "./Header";
import Main from "./Routes";

function App() {
    return (
        <div className="App">
            <SiteMap />
            <Main />
        </div>
    );
}

export default App;
