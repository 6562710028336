import React from "react";
import { Switch, Route } from "react-router-dom";

import Work from "./pages/work";
import AboutMe from "./pages/aboutMe";
import Services from "./pages/services";
import Contact from "./pages/contact";

const Main = () => {
    return (
        <Switch>
            <Route exact path="/">
                <AboutMe />
            </Route>
            <Route exact path="/work">
                <Work />
            </Route>
            <Route exact path="/services">
                <Services />
            </Route>
            <Route exact path="/contact">
                <Contact />
            </Route>
        </Switch>
    );
};

export default Main;
